<template>
  <vuestic-widget class="form-create-step mt-5">
    <ValidationObserver ref="questionStep">

      <div class="leadform-editor-container">
        <div class="design-box">
          <div class="border-bottom mb-4" v-for="(step, index) in form.questions[mainLanguageIndex].steps" :key="index">
            <h4 class="mt-2">Step {{ index + 1 }}</h4>
            <div class="row">
              <div class="col-11">
                <text-input v-model="step.question" label="Question" />
              </div>
              <div class="col-1" v-if="index != 0">
                <i class="fa fa-trash-o red-icon-delete" data-toggle="tooltip" data-placement="top" title="Delete Step"
                  @click="onDeleteStepClick(index)" />
              </div>
            </div>


            <div v-for="(option, optionsIndex) in step.options" :key="optionsIndex">
              <div class="row">
                <div class="col-6">
                  <text-input v-model="option.option" label="Options" />
                </div>
                <div class="col-4">
                  <tag-selector v-model="form.steps[index]['options'][optionsIndex]['tags']"></tag-selector>
                </div>

                <div class="col-2" v-if="step.options.length != 1">
                  <a href="javascript:;">
                    <i class="fa fa-trash-o red-icon-delete" data-toggle="tooltip" data-placement="top"
                      title="Delete Option" @click="onDeleteOptionClick(index, optionsIndex)" />
                  </a>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <button class="btn btn-sm btn-primary" @click="onAddOptionClick(index)">
                  <i class="fa fa-plus"></i> Add Option
                </button>
              </div>
            </div>
          </div>
          <div class="mt-4 text-right">
            <button class="btn btn-sm btn-primary" @click="onAddStepClick()">
              <i class="fa fa-plus"></i> Add Question
            </button>
          </div>
        </div>



      </div>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { formEmbeddedTypes } from '../../../../constants'
import Button from '../../../common/Button.vue'
import QuestionForm from '../QuestionForm'
import TagSelector from '../../../common/TagSelector'
export default {
  components: {
    Multiselect,
    Button,
    QuestionForm,
    TagSelector
  },
  props: {
    form: {
      type: [Object],
      default: null,
    },
    mainLanguageIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      types: JSON.parse(JSON.stringify(formEmbeddedTypes)),
      defaultLanguage: "en",
      tagsSelected: []
    }
  },
  mounted() {
  },
  methods: {
    onAddStepClick() {
      this.form.questions.forEach(element => {
        element.steps.push(JSON.parse(JSON.stringify(this.form.questions[this.mainLanguageIndex].steps[0])))
      });
      this.form.steps.push(JSON.parse(JSON.stringify(this.form.steps[0])))
    },
    onAddOptionClick(stepIndex) {
      this.form.questions.forEach(element => {
        element.steps[stepIndex].options.push({ "option": "" })
      });
      this.form.steps[stepIndex].options.push({ "tags": [] })
    },
    onDeleteStepClick(stepIndex) {
      this.form.questions.forEach(element => {
        element.steps.splice(stepIndex, 1);
      });
      this.form.steps.splice(stepIndex, 1)
    },
    onDeleteOptionClick(stepIndex, optionIndex) {
      this.form.questions.forEach(element => {
        element.steps[stepIndex].options.splice(optionIndex, 1);
      });
      this.form.steps[stepIndex].options.splice(optionIndex, 1)
    },
    async validateStep() {
      let stepValid = true
      stepValid = await this.updateOrCreate()
      return stepValid
    },
    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)
      await this.$store
        .dispatch(
          'install/updateForm',
          this.form
        )
        .then((form) => {
          this.$emit('done', form)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })
      return stepValid
    }
  },
}
</script>

<style lang="scss">
.form-create-step {
  .design-box {
    width: 100%;
  }

  .widget-body {
    padding: 0px;
  }

  .card {
    border-radius: 0px;
  }

  .red-icon-delete {
    padding-top: 23px;
    color: red;
    font-size: 30px;
  }

  .blue-icon-add {
    padding-left: 19px;
    padding-bottom: 12px;
    color: #1a76d2;
    font-size: 30px;
  }
}
</style>