<template>
  <div :style="containerStyle" class="question-form">
    <form>
      <div>
        <p :style="responseContainerStyle"> {{ form.questions[mainLanguageIndex].steps[currentQuestionIndex].question }}</p>
         <div v-for="(option, index) in form.questions[mainLanguageIndex].steps[currentQuestionIndex].options" :key="index" :style="buttonContainerStyle">
        <button type="button"  class="options" @click="selectOption(index)" :style="optionStyle(index)" @mouseenter="setHover(index, true)" @mouseleave="setHover(index, false)">
          <label :class="{ selected: isSelected.includes(index),  'br-50' : !form.is_allow_multi_select}" class="custom-label">
            {{ option.option }} 
          </label>
        </button>
      </div>

        <div class="next-back-container">
           <button type="button" v-if="currentQuestionIndex != 0" :style="buttonBackStyle" @mouseenter="isBackHover = true" @mouseleave="isBackHover = false"   @click="previousQuestion" :disabled="currentQuestionIndex === 0" >
            {{ languages.triggerButton[`BackLabel`] }}
          </button>

          <button type="button" :style="buttonNextStyle" @mouseenter="isNextHover = true" @mouseleave="isNextHover = false" @click="nextQuestion"  :disabled="currentQuestionIndex === form.questions[mainLanguageIndex].steps[currentQuestionIndex].question.length - 1">
            {{ languages.triggerButton[`NextLabel`] }}
          </button>

         
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    languages: {
      type: [Object],
      default: null,
    },
     mainLanguageIndex: {
      type: Number,
      default: 0,
    },
    form: {
      type: [Object],
      default: null,
    },
  },
  data() {
    return {
      isHover: [],
      isSelected: [],
      isBackHover: false,
      isNextHover: false,
      currentQuestionIndex: 0,
      textColor: "red",
    }
  },
  computed: {
    responseContainerStyle() {
      return {
        margin: 0,
        paddingTop: `${this.form.properties.question.Padding}px`,
        paddingBottom: `${this.form.properties.question.Padding}px`,
        textAlign: this.form.properties.question.Align,
        fontSize: `${this.form.properties.question.FontSize}px`,
        color: this.form.properties.question.TextColor
      }
    },
    containerStyle() {
      return {
        width: `${this.form.properties.styling.MaxWidth}px`,
        backgroundColor: this.form.properties.styling.BackgroundColor,
        borderRadius: `${this.form.properties.styling.Roudness}px`,
        padding: `${this.form.properties.styling.Padding}px`,
        border: `1px solid ${this.form.properties.styling.BorderColor}`,
      }
    },
   
    buttonBackStyle() {
      return {
        backgroundColor: this.isBackHover ? this.form.properties.triggerButton.HoverBackgroundColor : this.form.properties.triggerButton.BackgroundColor,
        color: this.form.properties.triggerButton.Color,
        border: `1px solid ${this.isBackHover ? this.form.properties.triggerButton.HoverBorderColor : this.form.properties.triggerButton.BorderColor}`,
        borderRadius: `${this.form.properties.triggerButton.Roudness}px`,
        fontSize: `${this.form.properties.triggerButton.FontSize}px`,
        padding: `${this.form.properties.triggerButton.Padding}px`,
        transition: `all 0.4s ease 0s`,
        width: '100px'
      }
    },
    buttonNextStyle() {
      return {
        backgroundColor: this.isNextHover ? this.form.properties.triggerButton.HoverBackgroundColor : this.form.properties.triggerButton.BackgroundColor,
        color: this.form.properties.triggerButton.Color,
        border: `1px solid ${this.isNextHover ? this.form.properties.triggerButton.HoverBorderColor : this.form.properties.triggerButton.BorderColor}`,
        borderRadius: `${this.form.properties.triggerButton.Roudness}px`,
        fontSize: `${this.form.properties.triggerButton.FontSize}px`,
        padding: `${this.form.properties.triggerButton.Padding}px`,
        transition: `all 0.4s ease 0s`,
        width: '100px'
      }
    },
    buttonContainerStyle() {
      return {
        paddingTop: `15px`,
      }
    },
  }, methods :{
    previousQuestion() {
      this.isSelected = [];
      this.isHover= [];
      this.currentQuestionIndex--;
    },
    nextQuestion() {
      this.isSelected = [];
      this.isHover= [];
      this.currentQuestionIndex++;
    },
     setHover(index, value) {
      this.isHover= [];
      this.$set(this.isHover, index, value);
    },
    selectOption(index) {
       if (this.isSelected.includes(index)) {
        this.isSelected = this.isSelected.filter(item => item !== index);
      } else {
        if (!this.form.is_allow_multi_select) {
            this.isSelected = []
        }
         this.isSelected.push(index)
      }
    },
    optionStyle(index){
      const hoverState = this.isHover[index] || this.isSelected.includes(index)
      return {
        textAlign: this.form.properties.questionOptions.Align,
        backgroundColor: hoverState? this.form.properties.questionOptions.HoverBackgroundColor : this.form.properties.questionOptions.BackgroundColor,
        color: hoverState? this.form.properties.questionOptions.HoverTextColor : this.form.properties.questionOptions.Color,
        border: `1px solid ${hoverState ? this.form.properties.questionOptions.HoverBorderColor : this.form.properties.questionOptions.BorderColor}`,
        borderRadius: `${this.form.properties.questionOptions.Roudness}px`,
        fontSize: `${this.form.properties.questionOptions.FontSize}px`,
        padding: `${this.form.properties.questionOptions.Padding}px`,
        transition: `all 0.4s ease 0s`,
        width: '100%'
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.question-form {
  margin: auto;
  form {
    margin-bottom: 0;
  }
  .next-back-container {
    padding: 15px;
    display: flex;
    justify-content: space-evenly;
  }
  .question {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
}
  .custom-label {
    position: relative;
    padding-left: 30px; /* Adjust as needed */
    
  }

  .custom-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -4px;
    border: 1px solid black;
    width: 1.6rem;
    height: 1.6rem;
    border-width: 3px;
    border-color: #ebf2fe;
  }

  .custom-label.selected:before {
    background: no-repeat;
    background-size: 70%;
    background-position: center;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
  }

  .br-50:before {
    border-radius: 50%;
  }



</style>