<template>
  <div v-if="section">
    <div class="row">
      <div class="col-md-6">
          <AlignInput v-model="section.Align" label="Align" />
        </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BackgroundColor" label="Background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BorderColor" label="Border color" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.HoverBackgroundColor" label="Hover background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.HoverBorderColor" label="Hover border color" />
      </div>
      <div class="col-md-6">
          <ColorInput v-model="section.HoverTextColor" label="Hover Color" />
        </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Roudness" label="Roudness" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.Color" label="Color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.FontSize" label="Font size" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Padding" label="Padding" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from '../../EmbeddedForms/Components/ColorInput'
import NumberInput from '../../EmbeddedForms/Components/NumberInput'
import IconInput from '../../EmbeddedForms/Components/IconInput'
import AlignInput from '../../EmbeddedForms/Components/AlignInput'
export default {
  components: {
    ColorInput,
    NumberInput,
    IconInput,
    AlignInput,
  },
  props: {
    section: {
      type: [Object],
      default: null,
    },
    languages: {
      type: [Object],
      default: null,
    },
    type: {
      type: String,
      default: ''
    }
  },
}
</script>