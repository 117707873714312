<template>
  <button :class="classes" :type="type" :disabled="loading || disabled" @click="$emit('click')">
    <template v-if="!loading">
      <slot></slot>
    </template>
    <template v-else>
      <i class="fa fa-refresh fa-spin"></i>
    </template>
  </button>
</template>
<script>
  export default {
    props: ['loading', 'classes', 'type', 'disabled'],
  }
</script>